


































import { Component, Vue, Prop } from 'vue-property-decorator'
import QuestionItem from '@/components/Question/QuestionItem.vue'

@Component({
  components: {
    QuestionItem
  }
})
export default class extends Vue {
  @Prop() questionList!: Questionnaire.IQuestionItem[]
  @Prop({ default: 'normal' }) type?: string

  private delQuestion (index: number) {
    this.$emit('delQuestion', { index })
  }
}
