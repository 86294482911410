












































import { Component, Vue, Prop } from 'vue-property-decorator'
import { question_type_list } from '@/api/naire'

  @Component
export default class extends Vue {
    private question_type = {}
    @Prop({ required: true }) private topic!: Questionnaire.IQuestionItem
    @Prop({ required: true }) private index!: number
    public async questionTypeListData () {
      const res = await question_type_list()
      if (!res.success) return
      this.question_type = res.data ? res.data : {}
    }
    mounted () {
      this.questionTypeListData()
    }
}
