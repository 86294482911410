var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form-item',{attrs:{"label":"题目","prop":'topic.' + _vm.index + '.question',"rules":{
      required: true, message: '题目内容不能为空', trigger: 'blur'
    }}},[_c('el-input',{attrs:{"placeholder":"请输入题目内容"},model:{value:(_vm.topic.question),callback:function ($$v) {_vm.$set(_vm.topic, "question", $$v)},expression:"topic.question"}})],1),_c('el-form-item',{attrs:{"label":"题目说明"}},[_c('el-input',{attrs:{"placeholder":"请输入题目说明，可以为空"},model:{value:(_vm.topic.description),callback:function ($$v) {_vm.$set(_vm.topic, "description", $$v)},expression:"topic.description"}})],1),_c('el-form-item',{attrs:{"label":"作物分类"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.topic.question_type),callback:function ($$v) {_vm.$set(_vm.topic, "question_type", $$v)},expression:"topic.question_type"}},_vm._l((_vm.question_type),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{attrs:{"oninput":"value=value.replace(/[^\\d]/g,'')","placeholder":"请输入排序,数字越大越靠前"},model:{value:(_vm.topic.sort),callback:function ($$v) {_vm.$set(_vm.topic, "sort", $$v)},expression:"topic.sort"}})],1),_c('el-form-item',{attrs:{"label":""}},_vm._l((_vm.topic.options),function(option,opIndex){return _c('div',{key:opIndex,staticClass:"option-item"},[_c('el-row',{attrs:{"gutter":22}},[_c('el-col',{attrs:{"span":18}},[_c('el-form-item',{attrs:{"label":("选项 " + (opIndex + 1)),"prop":'topic.' + _vm.index + '.options.' + opIndex + '.content',"rules":{
              required: true, message: '选项内容不能为空', trigger: 'blur'
            }}},[_c('el-input',{attrs:{"placeholder":"请输入选项内容"},model:{value:(option.content),callback:function ($$v) {_vm.$set(option, "content", $$v)},expression:"option.content"}})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-plus","size":"mini"},on:{"click":function($event){return _vm.addOption(_vm.index)}}}),_c('el-button',{attrs:{"type":"warning","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.delOption(_vm.index, opIndex)}}})],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":18}},[_c('el-form-item',{attrs:{"label":"描述"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2,maxRows: 5 },"placeholder":"请输入选项描述"},model:{value:(option.desc),callback:function ($$v) {_vm.$set(option, "desc", $$v)},expression:"option.desc"}})],1)],1)],1),_c('el-row',{staticStyle:{"margin-bottom":"5px"}},[_c('el-col',{attrs:{"span":18}},[_c('el-form-item',{attrs:{"label":"图片"}},[_c('el-input',{attrs:{"placeholder":"请输入图片地址"},model:{value:(option.image),callback:function ($$v) {_vm.$set(option, "image", $$v)},expression:"option.image"}})],1)],1)],1),_c('div',{staticClass:"option-addtion"},[_c('el-form-item',{attrs:{"label":"附加内容：","prop":'topic.' + _vm.index + '.options.' + opIndex + '.isAddition',"rules":{
            required: true, message: '选项内容不能为空', trigger: 'blur'
          }}},[_c('el-switch',{attrs:{"active-text":"有","inactive-text":"无"},model:{value:(option.isAddition),callback:function ($$v) {_vm.$set(option, "isAddition", $$v)},expression:"option.isAddition"}})],1)],1)],1)}),0),_c('el-form-item',{attrs:{"label":"必填项","prop":'topic.' + _vm.index + '.isRequired',"rules":{
      type: 'boolean', required: true, message: '请选择是否为必填项', trigger: 'change'
    }}},[_c('div',{staticClass:"option-addtion"},[_c('el-switch',{attrs:{"active-text":"有","inactive-text":"否"},model:{value:(_vm.topic.isRequired),callback:function ($$v) {_vm.$set(_vm.topic, "isRequired", $$v)},expression:"topic.isRequired"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }